<template>
  <section class="content">
    <div class="team-wrapper">
      <h2 class="label-sub-title heavy c-white">
        THE MIILK TEAM
      </h2>

      <template>
        <ul class="list" v-if="isMobile">
          <member v-for="(member, idx) in members" :member="member" :key="idx" @infoClicked="setActive"></member>
        </ul>

        <template v-else>
          <ul class="list-half">
            <member v-for="(member, idx) in evenMembers" :member="member" :key="idx" @infoClicked="setActive"></member>
          </ul>
          <ul class="list-half">
            <member v-for="(member, idx) in oddMembers" :member="member" :key="idx" @infoClicked="setActive"></member>
          </ul>
        </template>
      </template>
    </div>

    <div class="team-wrapper">
      <h2 class="label-sub-title heavy c-white">
        Advisory
      </h2>

      <template>
        <ul class="list" v-if="isMobile">
          <member v-for="(member, idx) in advisors" :member="member" :key="idx" @infoClicked="setActive"></member>
        </ul>

        <template v-else>
          <ul class="list-half">
            <member v-for="(member, idx) in evenAdvisors" :member="member" :key="idx" @infoClicked="setActive"></member>
          </ul>
          <ul class="list-half">
            <member v-for="(member, idx) in oddAdvisors" :member="member" :key="idx" @infoClicked="setActive"></member>
          </ul>
        </template>
      </template>
    </div>

    <!-- <div class="team-wrapper">
      <h2 class="label-sub-title heavy c-white">
        Miilkshaker
      </h2>

      <template>
        <ul class="list" v-if="isMobile">
          <member
            v-for="(member, idx) in miilkshakers"
            :member="member"
            :key="idx"
            @infoClicked="setActive"
          ></member>
        </ul>

        <template v-else>
          <ul class="list-half">
            <member
              v-for="(member, idx) in evenMiilkshakers"
              :member="member"
              :key="idx"
              @infoClicked="setActive"
            ></member>
          </ul>
          <ul class="list-half">
            <member
              v-for="(member, idx) in oddMiilkshakers"
              :member="member"
              :key="idx"
              @infoClicked="setActive"
            ></member>
          </ul>
        </template>
      </template>
    </div> -->
  </section>
</template>

<script>
import Member from "./member";

export default {
  props: {},
  data() {
    return {
      isMobile: false,
      members: [
        {
          name: "손재권",
          position: "CEO",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile1.png",
          info:
            "손재권 더밀크 창업자, CEO는 매일경제신문 실리콘밸리 특파원을 역임하고 스탠퍼드대학 방문 연구원으로 재직하는 등 오랜 기간 실리콘밸리에 머물며 혁신 기업과 최신 테크 트렌드를 취재했습니다. CES, 구글I/O, 페이스북F8 등 비롯한 주요 테크 컨퍼런스를 현장에서 한국에 전달해왔습니다. 실리콘밸리 혁신 기업들을 취재한 책 ‘파괴자들(Disruptors)’을 출간하기도 했습니다.",
          opened: false,
        },
        {
          name: "김홍석",
          position: "COO",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/hongdol_001.png",
          info:
            "김홍석 COO는 시러큐스 대학에서 데이터 사이언스 석사학위를 취득한 데이터 과학자입니다. SK홀딩스, SK텔레콤에서 일했고, 미국 실리콘밸리에서 한 차례 교육 스타트업을 창업한 경력도 있습니다. 현재 더밀크 운영을 총괄하고 있습니다.",
          opened: false,
        },
        {
          name: "박원익",
          position: "뉴욕 플래닛장",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile2.png",
          info:
            "박원익 더밀크 뉴욕플래닛장은 이데일리와 조선비즈에서 글로벌 테크 기업과 국내외 스타트업을 취재했습니다. 조선비즈 실리콘밸리 특파원으로 근무하며 ‘실리콘밸리의 도전자들’ 시리즈 인터뷰를 썼습니다. 중국 증시 투자 입문서 ‘중국 주식 1억이 10년 만에 175억(제2의 텐센트를 찾아라)’를 집필했습니다.",
          opened: false,
        },
        {
          name: "권순우",
          position: "미 서던 플래닛장",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile5.png",
          info:
            "미국 서던 플래닛장은 한국 경제방송국에 근무하면서 금융감독원과 한국은행 출입 기자로 활동했고, 중앙일보 애틀랜타 지사 편집국 에디터를 지냈습니다. 경제 유튜브 방송 ‘경제가 보인다’를 기획하고, 진행자로 활동했습니다. 전기차와 EV 배터리 등 신재생 에너지 생산 거점이 확대되고 있는 미국 남동부 애틀랜타에서 생생한 현장 소식을 전하고 있습니다. 조지아주립대(GSU)에서 MBA를 취득, 경제, 경영, 부동산 분야에도 관심이 많아 테크와 관련한 자산시장 동향을 두루 취재하고 있습니다. 현재 조지아텍(GT)에서 방문연구원으로 활동하고 있습니다.",
          opened: false,
        },
        {
          name: "윤서연",
          position: "Project manager",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/member-sy-yoon.png",
          info:
            "다양한 IT분야의 채용 전문가로 더밀크의 코리아법인 운영과 HR 업무를 담당하고 있습니다. 그리고 더밀크가 찾은 실리콘밸리의 인사이트를 해외연수 프로그램들과 다양한 컨퍼런스를 통해 공유하기위해 여러 프로젝트들을 기획&운영하고 있습니다.",
          opened: false,
        },
        {
          name: "크리스정",
          position: "Journalist",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/member-chris-jung.png",
          info:
            "뉴욕에서 미 투자 경제 블로그인 itechkorea를 운영하고 있으며 ITK라는 필명으로도 활동중입니다. IT 엔지니어 출신으로 이후 미국 부동산과 세법(Tax Law)을 공부했고 금융시장을 보며 시장의 시그널을 파악하기 위해 노력하고 있습니다.",
          opened: false,
        },
        {
          name: "김세진",
          position: "Reporter",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile6.png",
          info:
            "김세진 기자는 매일경제 산하 디스트리트와 코인데스크코리아에서 블록체인 기술·암호화폐 금융·웹3인프라를 전문으로 취재했습니다. 정치학을 전공하고, 로이터 레피니티브에 게재된 '중앙은행 디지털화폐(CBDC)에 대한 개괄 및 고찰' 보고서를 공동집필하기도 했습니다. 현재 더밀크에서 크립토 투자와 비즈니스, 테크, 거버넌스 기사를 담당하고 있습니다.",
          opened: false,
        },
        {
          name: "한연선",
          position: "Head of Research",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/han.png",
          info:
            "한연선 연구원은 딜로이트와 IBM 코리아에서 마켓 리서치, 조직문화, 커뮤니케이션, 인력관리 및 기업교육 컨설팅 경력을 쌓았습니다. 캘리포니아 대학교 리버사이드(UCR)에서 교육정책 박사과정을 수료했으며 현재 더밀크에서 리서치를 담당하고 있습니다.",
          opened: false,
        },
        {
          name: "황재진, Ph.D.",
          position: "Researcher",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile12.png",
          info:
            "황재진 연구원은 오하이오 주립대학교에서 산업공학 박사학위를 취득했습니다. 기술의 발전으로 인한 일과 삷의 변화에 대해 관심이 많습니다. 현재 시카고 지역에 거주 중이며 더밀크에서 리서치를 담당하고 있습니다. 주요 저서로는 [내 삶 속의 인간공학], [웹3.0과 메타버스가 만드는 디지털 혁명], [사례 분석으로 배우는 데이터 시각화] 등이 있습니다.",
          opened: false,
        },
        {
          name: "윤영진",
          position: "Researcher",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile10.png",
          info:
            "윤영진 연구원은 글로벌 컨설팅 펌의 서울, 시카고 오피스에서 컨설턴트 경력과 뉴욕에 위치한 글로벌 투자은행 근무 경험이 있습니다. 미국 워싱턴대(Washington University in St. louis)에서 MBA를 수료했습니다. 저서로는 ‘웹3.0과 메타버스가 만드는 디지털 혁명', '쉽게 배우는 4차 산업 혁명 기술과 비즈니스 트렌드’ 등이 있고 '데이터 스토리(원제: Data Story)'를 감수했습니다.",
          opened: false,
        },
        {
          name: "서혜림",
          position: "Researcher",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/hyerim.png",
          info:
            "서혜림 연구원은 보스턴 대학교에서 저널리즘을 공부했고, 보스턴 글로브와 WTBU에서 취재 및 방송 경력을 쌓았습니다. 뿌리가 있는 한국과 세계의 중심인 미국을 잇는 다리가 되고 싶어 더밀크에 합류하였습니다. 숨겨진 이야기들을 찾아 세계 각국을 여행하며 리서처로 일하고 있습니다.",
          opened: false,
        },
        {
          name: "김기림",
          position: "Researcher",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/girim.png",
          info:
            "김기림 리서처는 미네소타 주립대학교에서 저널리즘을 전공했고, 홍익대학교 국제디자인전문대학원 IDAS에서 UX를 공부했습니다. 경제 주간지에서 IT분야 기자로, 스타트업에서 마켓 리서처로 경력을 쌓았습니다. 매일 더 나은 사람이 되어야 좋은 글도 쓸 수 있다고 믿습니다. 현재 더밀크에서 리서치를 담당하고 있습니다.",
          opened: false,
        },
        {
          name: "김현지, Ph.D.",
          position: "UX Content Manager",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile13.png",
          info:
            "김현지 UX 콘텐츠 매니저는 노스캐롤라이나 주립대학교 산업디자인학과에서 디자인 박사학위를 취득한 UX 디자이너 & 리서처입니다. 아카데믹 분야에서 약 7년간 사용자 중심(UX) 디자인 프로젝트들을 진행하였습니다. 정량적 & 정성적 연구 방법을 기반으로 데이터를 분석하여 디자인 가이드라인을 개발하였습니다.",
          opened: false,
        },
      ],
      advisors: [
        {
          name: "Archi Kong",
          position: "",
          imageUrl:
            "https://assets.themiilk.com/commons/service/about/members/13.svg",
          info:
            "카네기 대학교 컴퓨터 공학과 석사 취득 후, LG, 삼성, 레노버를 거치며 20년간 대기업 전략, 투자, 디지털혁신 및 신사업 개발업무 경험을 가지고 있습니다.  2014년부터 실리콘 밸리로 옮겨 startups과 아시아 대기업의 partnership & 투자 업무를 담당해 왔습니다.  현재 CJ그룹의 digital 혁신을 추진하는 CDO조직내 오픈이노베이션 팀의 리더이자, 실리콘밸리에서 자문 및 멘토링으로 여러 startups들을 돕고 있으며, 더밀크의 founding advisor로도 활동하고 있습니다.",
          opened: false,
        },
        {
          name: "김인순",
          position: "",
          imageUrl:
            "https://s3.ap-northeast-2.amazonaws.com/assets.themiilk.com/profile/profile4.png",
          info:
            "김인순 기자는 전자신문 ICT융합부 데스크 출신으로 보안·소프트웨어 분야를 전문으로 취재한 베테랑 기자입니다. 기자협회 이달의 기자상을 두 차례 수상했고, 실리콘밸리 혁신 기업들을 취재한 책 ‘파괴자들 ANTI의 역습‘을 집필했습니다.",
          opened: false,
        },
      ],
      miilkshakers: [
        {
          name: "정구민",
          position: "",
          imageUrl:
            "https://assets.themiilk.com/commons/service/about/members/15.svg",
          info:
            "네오엠텔과 SK텔레콤을 거쳐서 국민대학교 전자공학부 교수로 재직 중이입니다. 현대자동차, LG전자, 삼성전자, 네이버 등 국내 주요 기업들의 자문교수 활동과 국제모터쇼, CES, MWC, IFA 등 해외 전시회 참여를 통하여 최신 기술과 서비스 동향을 정리 분석하고 진화 방향성을 제시합니다. \n",
          opened: false,
        },
        {
          name: "주영섭",
          position: "",
          imageUrl:
            "https://assets.themiilk.com/commons/service/about/members/16.svg",
          info:
            "기업 전문경영인 출신으로 정부 관료, 대학교수 등 40년간 산학연관 요직을 두루 거친 산업&기술 전문가입니다. 현대차그룹과 미국 GE그룹 CEO를 거쳐 지식경제부 R&D전략기획단 주력산업 총괄MD(차관급)와 대통령 직속 국민경제자문회의 위원, 서울대 초빙교수 역임후 최초의 기업인 출신 중소기업청장으로 국가경제 발전에 기여해왔습니다. 현재 고려대 교수와 한국ICT융합네트워크 회장으로 우리나라 스타트업과 중소중견기업 육성에 힘쓰고 있습니다.",
          opened: false,
        },
        {
          name: "이용덕",
          position: "",
          imageUrl:
            "https://assets.themiilk.com/commons/service/about/members/17.svg",
          info:
            "13년 동안 엔비디아 한국 지사 대표로 재직했습니다. 2018년 엔비디아 코리아를 떠나 멘토링 재능 기부 프로젝트인 ‘드림앤퓨쳐랩스’를 시작했습니다. 2019년에는 AI 전문 솔루션 회사 ㈜바로AI를 설립했습니다. 이화여대 겸임교수로 재직하며 젊은이들에게 꿈과 미래에 대해 이야기하고 있습니다. 저서로 '5년 후 나는 어디에 있을 것인가' 등이 있습니다.",
          opened: false,
        },
        {
          name: "최형욱",
          position: "",
          imageUrl:
            "https://assets.themiilk.com/commons/service/about/members/18.svg",
          info:
            "innovation catalyst이자 기술전략가로서 기업들의 혁신을 촉매하고 있습니다. 아시아발 혁신과 협력의 시대를 위해 'Pan Asia Network'을 공동 설립했습니다. 미래전략 싱크탱크 ‘퓨처디자이너스’의 future designer로서 신기술과 선한 영향력으로 만들어 낼 미래상과 새로운 가치를 제시하고 있습니다. 중국 선전과 협력하여 하드웨어 엑셀레이션 플랫폼 'Fast.B'를 만들고 있습니다.",
          opened: false,
        },
      ],
    };
  },
  computed: {
    oddMembers: function () {
      const members = [];
      this.members.forEach((member, idx) => {
        if (idx % 2 == 1) {
          members.push(member);
        }
      });
      return members;
    },
    evenMembers: function () {
      const members = [];
      this.members.forEach((member, idx) => {
        if (idx % 2 == 0) {
          members.push(member);
        }
      });
      return members;
    },

    oddAdvisors: function () {
      const members = [];
      this.advisors.forEach((member, idx) => {
        if (idx % 2 == 1) {
          members.push(member);
        }
      });
      return members;
    },
    evenAdvisors: function () {
      const members = [];
      this.advisors.forEach((member, idx) => {
        if (idx % 2 == 0) {
          members.push(member);
        }
      });
      return members;
    },

    oddMiilkshakers: function () {
      const members = [];
      this.miilkshakers.forEach((member, idx) => {
        if (idx % 2 == 1) {
          members.push(member);
        }
      });
      return members;
    },
    evenMiilkshakers: function () {
      const members = [];
      this.miilkshakers.forEach((member, idx) => {
        if (idx % 2 == 0) {
          members.push(member);
        }
      });
      return members;
    },
  },
  mounted() {
    this.mobileCheck();
    window.addEventListener("resize", this.mobileCheck, { passive: true });
  },
  components: {
    Member,
  },
  methods: {
    mobileCheck: function () {
      if (window.innerWidth <= 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    setActive: function (member) {
      member.opened = !member.opened;
    },
  },
};
</script>
